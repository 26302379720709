::-webkit-scrollbar
{
    width: 0.75rem;
}
::-webkit-scrollbar-track{
    background-color: rgb(231, 231, 231);

}
::-webkit-scrollbar-thumb
{
  width: 0.5rem;
    background-color: rgb(173, 171, 171);
    border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover
{
    background-color: rgb(131, 131, 131);
    border-radius: 100px;
}
::-moz-selection { /* Code for Firefox */
  color: inherit;
  background: rgba(128, 202, 236, 0.4);
}

::selection {
  color: inherit;
  background: rgba(128, 202, 236, 0.4);
}
body {
  background-color: #333037;
  margin: 0;
  font-family: 'Helvetica',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
