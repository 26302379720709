@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;600;700&display=swap');
.nav
{

  background-color: white;
  width: 100%;
  top: 0;
  min-height: 63px;
  position: fixed;
  z-index: 2;
}
.logo
{
  position: absolute;
  top: 0;
  z-index: 1;
  left: 10px;
  height: 63px;


}
.line
{
  font-family: 'Josefin Sans', sans-serif;
  display: inline;
  font-size: 25px;
  color: grey;
}
.line::after
{
  content: '|';
}
.navbuttons
{
  list-style-type:none;
  text-align: center;
  padding-left:70px;
  padding-right: 70px;
  margin: 8px;

}
.navlist
{
  display:inline;
}



.navbtn {
  display: inline-block;
  text-decoration: none;
  position: relative;
  top: 0px;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 25px;
  margin: 10px;
  color: black;
  transition-property: color, top;
  transition-duration: .2s;
  transition-timing-function: ease-out;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.navbtn::after {
  content: '';
  position: relative;
  display: block;
  width: 0;
  top: 0px;
  height: 3px;
  background: black;
  transition-property: background, width, top;
  transition-duration: .2s;
  transition-timing-function: ease-out;
  
}

.navbtn:hover::after {
  width: 100%;
  background: #DC6262;
  top: 3px;
}

.navbtn:focus::after {
  width: 100%;
  background: #DC6262;
  top: 3px;
}
.navbtn:focus
{
  position: relative;
  top: -3px;
  color: #DC6262;
  outline: none;
}

.navbtn:hover
{
  position: relative;
  top: -3px;
  color: #DC6262;
}

.border
{
  display: inline;
}
.burgermenu
{
  visibility: hidden;
  position: absolute;
    top: 18px;
    float: right;
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .nav
  {
    z-index: 1;
  }
  .navbuttons
  {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -30rem;
    background-color: whitesmoke;
    width: 100%;
    z-index: -1;
    margin: 62px 0 0 0;
    
    padding-bottom: 3rem;
    padding-top: 3rem;
  }
  .active
  {
    transition: top 0.3s ease-out;
    top: 0;
  }
 
.burgermenu
  {
    visibility: visible;
    position: relative;
    top: 15px;
    right: 15px;
    float: right;
    height: 4vh;
    height: 30px;
    width: 30px;
  }
.burgermenu:checked
  {
    background-color: grey;
  }
  .navbtn
  {
    display: block;
  }
  .color
  {
    background-color: white;
  width: 100%;
  top: 0;
  min-height: 63px;
  position: fixed;
  z-index: 0;
  }
  .line
  {
    display: block;
    margin: auto;
    width: 90%;
    height: 2px;
    background-color: grey;
    
  }
  .line::after
  {
    content: '';
  }
}
