@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;600;700&display=swap');
html
{
    height: 100%;
    overflow-x: hidden;
}
.Intro
{
    background-image: url(../img/IntroImg.svg);
    background-color: #cccccc;
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.about1
{
    background-image: url(../img/background2.svg);
    background-color: #cccccc;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 60px;
    padding-bottom: 60px;
}
.pwhite
{
    color: white;
    text-align: center;
    margin-right: 30%;
    margin-left: 30%;
    font-size: 20px;
}
.eye
{
    display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 8%;
  padding-bottom: 5%;
  height: 170px;
}
h2, h1
{
    font-family: 'Josefin Sans', sans-serif;
    text-align: center;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
}
h2
{
    font-size: 40px;
    color: #FFBC21;
}
h2.title
{

    margin-bottom: 30px;
}
.title2
{
    padding-top: 40px;
    margin: 0;
    color: black;
}
h1
{
    font-size: 50px;
    color: white;
}

.LearnMore
{
    font-family: 'Josefin Sans', sans-serif;
    position: absolute;
    top: 75%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: white;
    border-radius: 100px;
    border: none;
    font-size: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 40px;
    padding-left: 40px;
    text-decoration: none;
    color: black;
    box-shadow:0px 3px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.contain
{
    background-color: white;
    width: 60%;
    margin: auto;
    padding-bottom: 30px;
}
.backimg
{
    background-attachment: fixed;
}
.pcontainer
{
    margin: 0;
    padding-bottom: 40px;
    padding-right: 60px;
    padding-left: 60px;
}
.pcontainer:nth-child(1)
{
    padding-top: 50px;
}
.pcontainer:nth-child(4)
{
    padding-bottom: 20px;
}
.purple
{
    color: #A44FC2;
}
#checkitout
{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 22px;
    display: block;
    font-weight: normal;
    text-align: center;
    margin: 0;
    padding-bottom: 20px;
}
#Manibtn
{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
   background: linear-gradient(120deg, #BE76F5 , #DC6262, #BE76F5);
   background-size: 200% 200%;
   background-position: top left;
   color: white;
   border-radius: 100px;
   text-decoration: none;
   padding: 7px 20px;
   box-shadow: 0 3px 6px rgba(0,0,0,.2);
    
}   
#Manibtn:hover
{
    background-position: bottom right;
    animation: btn .3s ease-out;
}

@keyframes btn {
    from {background-position: top left;}
    to{background-position: bottom right;}
}


.center
{
    text-align: center;
    margin-bottom: 20px;
}
table
{
    width: 70%;
    margin:auto;
}
td
{
    width: 33.3%;
}
.social
{
    display: flex;
  justify-content: center;
  height: 40px;
}
.footsocial
{
    position: relative;
    top: 8px;
    float: right;
    height: 30px;
    margin-right: 5px;
    margin-left: 5px;
}

.container
{
    background-color: white;
    border-radius: 40px;
    width: 250px;
    margin: auto;
    height: 400px ;
}
.container:nth-child(1)
{
    margin-left:30px;
}
.container:nth-last-child(1)
{
    margin-right:50px;
}
.pfp
{
    position: relative;
    top: -30px;
    background-color: grey;
    border-radius: 50% 50%;
    height: 150px;
    width: 150px;
    margin: auto;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}
h5
{
    position: relative;
    top:-10px;
    text-align: center;
    margin: auto;
    margin-bottom: 12px;
    font-weight: normal;
    
}
.grid
{
    width: 100%;
    overflow: auto;
    padding-top: 30px;
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    margin-right: 30px;
    margin-top: 30px;
    cursor: grab;
}
.grid::-webkit-scrollbar
{
    height: 0.5rem;
}
.grid::-webkit-scrollbar-track{
    background-color: transparent;
}
.grid::-webkit-scrollbar-track:hover{
    background-color: rgb(65, 59, 65);
}
.grid::-webkit-scrollbar-thumb
{
    background-color: rgb(81, 78, 82);
    border-radius: 100px;
}.grid::-webkit-scrollbar-thumb:hover
{
    background-color: rgb(112, 101, 114);
    border-radius: 100px;
}
.count
{
    font-size: 100px;
    margin-left: 90px;
    color: #BE76F5;
}
.rules
{
    margin-left: 155px;
    margin-right: 90px;
}
h3{
    font-family: 'Josefin Sans', sans-serif;
    display: inline;
}

#Footer
{
    height: 80px;
    padding: 30px;
    padding-bottom: 50px;
}
#footname
{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin:auto;
    font-family: 'Josefin Sans', sans-serif;
    color: white;
}
.modtitle
{
    font-family: "Josefin Sans";
    font-size: 23px;
    font-weight: 600;
}
.modbio
{
    text-align: left;
    font-size: 14px;
    margin-left:20px ;
    margin-right:20px ;
    height: 90px;
}
.email
{
    position: relative;
    top: -12px;
    text-align: center;
    margin: 0;
}
.modemail
{
font-size: 14px;
margin: 0;
font-weight: 600;
color:rgb(231, 133, 133);

}
.modlinks
{
    flex: 1;
    height: 20px;
    margin: auto;
}
.modlinkimg
{
    display: block;
    margin: auto;
    height: 20px;
}
.modlinkcontain
{
    display: flex;
    width: 70%;
    margin: auto;
}
.chip
{
    background-color: blue;
    font-size: 16px;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    border-radius: 100px;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
}

.latin
{
    background-color:rgba(60, 194, 60, 0.4) ;
}
.southeast
{
    background-color: rgba(198, 89, 219, 0.4);
}
.africa
{
    background-color: rgba(207, 27, 102, 0.4);
}
.middle
{
    background-color: rgba(243, 137, 16, 0.4);
}
.south
{
    background-color: rgba(20, 100, 206, 0.4);
}
.female
{
    background-color: rgba(255, 128, 155, 0.4);
}
.neutral
{
    background-color: rgba(255, 187, 0, 0.4);
}
.male
{
    background-color: rgba(117, 79, 53, 0.4);
}

.regular
{
    width: 100%;
    background-color: white;
    min-height: 100vh;
    padding-bottom: 90px;
}
.header
{
    background-color: #333037;
    height: 75px;
}
.content
{
    width: 40%;
    margin: auto;
}
.content-title
{
    font-size: 40px;
    color: black;
    padding: 100px 0;
    margin-bottom: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 -80px 50px -80px rgba(0,0,0,0.3);
}
.content-subtitle
{

    margin-top: 20px;
    color: black;
    text-align: left;
    font-size: 25px;
}
.content-subtitle2
{
    font-weight: normal;
    font-size: 20px;
}
li
{
    margin: 10px 0;
}
p
{
    line-height: 25px;
    margin-bottom: 30px;
}
.space
{
    margin: 50px 0;
}
.red
{
    color:rgba(238, 186, 81, 1);
}
.orange
{
    color:rgb(228, 154, 85);
}
.yellow
{
    color:rgba(228, 118, 85, 1);
    background-color: transparent;
}
.green
{
    color:rgba(220, 86, 135, 1);
}
.blue
{
    color: rgba(197, 79, 201, 1);
    
}
.purple
{
    color:rgba(101, 94, 206, 1);
}
.scroll-padding
{
    scroll-margin-top:63px;
}
.hidden
{
    transition: max-height .3s ease-in-out 0s;
    padding-left: 8px;
    overflow: hidden;
}
.question
{

    border-radius: 10px;
    border: none;
    font-size: 20px;
    text-align: left;
    font-family: "Josefin sans";
    margin: 10px 0;
    padding: 10px;
    cursor: pointer;
}
.flex
{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap: 5px
}
.flex2
{
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 5px
}
.regnav
{
    font-family: "Josefin sans";
    font-size: 18px;
    height: 8vh;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}
.bgred
{
    background-color:rgba(238, 186, 81, 0.5)
 
}
.bgorange
{
    background-color:rgba(228, 118, 85, 0.5);
   
}
.bggreen
{
    background-color:rgba(220, 86, 135, 0.5);

}
.bgblue
{
    background-color: rgba(197, 79, 201, 0.5);
}
.bgpurple
{
    background-color:rgba(101, 94, 206, 0.5);
  
}
.bggrey
{
    background-color: rgb(221, 221, 221);
}
.resources
{
    color:#333037;
    font-weight: bold;
    text-decoration: underline rgb(156, 156, 156);
}
.search{
    display: block;
    margin:auto;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 14px;
    padding: 10px;
    width:50%;
    border-radius: 50px;
    border:solid 1px grey;
    transition: width 0.3s ease-in-out;
}
.search:focus
{
    width: 90%;
    border: solid 2px black;
    transition: width 0.3s ease-in-out;
}
.listul
{
    padding-left: 20px;
    margin: 0;
}
.list{
    padding: 5px;
}
.list:nth-child(even)
{
    background-color: whitesmoke;
}
input[type="checkbox"]
{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
}
.regfilter
{
    display: inline-block;
    margin: auto;
    text-align: center;
    cursor: pointer;
    font-family: "Josefin sans";
    font-size: 18px;
    padding: 20px 0;
    width: 100%;
    border: none;
    border-radius: 10px;

}
.others-table
{
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}
.others-table thead tr 
{
    font-family: "Josefin Sans";
    text-align: left;
}

.others-table th,
.others-table td {
    width: auto;
    padding: 12px 15px;
    padding-right: 0px;
}

.others-table tbody tr
{
    border-bottom: 1px solid #dddd;
}

.others-table tbody tr:nth-child(even)
{

    background-color: #f3f3f3;
}

.others-table tbody tr:last-of-type
{
    border-bottom: 3px solid grey;
}
.not-bold
{
    color: black;
    text-decoration: underline rgb(156, 156, 156);
}

.break-word
{
    word-break: break-all;
}
.up
{
    position: fixed;
    bottom: 1%;
    right: 1%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    z-index: 2;
    font-size: 18px;
    border:2px solid white;
    background-color: #DC6262;
    color: white;
    cursor: pointer;
}
@media screen and (max-width: 1024px) {
    .up
{
    position: fixed;
    height: 50px;
    width: 50px;
    bottom: 3%;
    right: 5%;
}
.flex
{
    grid-template-columns: auto auto;
}
.regnav:nth-last-child(1)
{
    grid-column-start: 1;
    grid-column-end: 3;
}
.content-title
{
    background-size: 250%;
}
.eye
{
    padding-top: 22vh;
    padding-bottom: 5vh;
    height: 15vh;
}
h2
{
    font-size: 25px;
}
h1
{
    font-size: 30px;
}
.LearnMore
{
    font-size: 20px;
    padding: 12px 20px;
}
.pwhite
{
    font-size: 16px;
    margin: 2vh 3vw;
}
.contain
{
    width: 100%;
    padding-bottom: 4vh;
}
.backimg
{
    padding-top: 100px;
    padding-bottom: 100px;
}
.about1{
    padding: 4vh 0 4vh 0;
}
.pcontainer
{
    font-size: 16px;
    padding: 1vh 4vw;
}
.pcontainer:nth-child(1)
{
    padding-top: 4vh;
}
#checkitout
{
    font-size: 22px;
}
#Manibtn
{
    font-size: 17px;
    padding: 10px 17px;
}
table
{
    width: 100%;
}
.social
{
    height: 4.5vh;
}
.count{
    font-size: 30px;
    padding-left: 10px;
    margin: 0;
}
h3
{
    font-size: 15px;
    margin:auto;
}

.title{
    font-size: 25px;
}
.title2{
    padding-top: 3vh;
    font-size: 25px;
}
.rules
{
    font-size: 16px;
    margin-top: 5px;
    margin-right: 10px;
    margin-left: 35px;
}

#footname
{
    font-size: 14px;
}
#Footer
{
    padding-bottom: 70px;
}
.footsocial
{
    position: relative;
    top:12px;
    height: 3vh;
}
.content
{
    width: 95%;
}
}